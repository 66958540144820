import { gql } from '@apollo/client';

export const USER_LIST = gql`
query listUsers {
  listUsers {
    users {
      Username
      UserAttributes {
        Name
        Value
      }
      UserStatus
      UserCreateDate
      UserLastModifiedDate
      Enabled
      PreferredMfaSetting
      UserMFASettingList
      AuthEvents(limit: 5) {
        authEvents {
          EventId
          EventType
          CreationDate
          EventResponse
          EventRisk {
            RiskDecision
            RiskLevel
            CompromisedCredentialsDetected
          }
          ChallengeResponses {
            ChallengeName
            ChallengeResponse
          }
          EventContextData {
            IpAddress
            DeviceName
            Timezone
            City
            Country
          }
          EventFeedback {
            FeedbackValue
            Provider
            FeedbackDate
          }
        }
      }
    }
  }
}
`;
