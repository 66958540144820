/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:a61f56e6-4dfb-46d3-9224-076ff4437cee",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_2Huwn8dq2",
    "aws_user_pools_web_client_id": "3b8snpmbmuu8mpcat2040l0ual",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://2ol626ttbrewtimqz4ptty5aki.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
