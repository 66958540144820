import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import useCustomAlert from '../../../components/Alert/useCustomAlert'

import { CREATE_GROUPE } from '../query/groupMutation';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  spacing: {
    margin: theme.spacing(3, 2, 2),
  },
}));

export default function GroupCreateForm() {
  const classes = useStyles();

  const [groupName, setGroupNameInput] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const alert = useCustomAlert();

  const [createGroup] = useMutation(CREATE_GROUPE, {
    onCompleted: (data) => {
      console.log('Group create mutation completed:', data);
      alert.showSuccess(`Group ${groupName} successfully created !`);
      setIsSubmitDisabled(false);
      setGroupNameInput("");
    },
    onError: (error) => {
      console.log('Group create mutation error:', error);
      alert.showError(error.toString());
      setIsSubmitDisabled(false);
    }
  });

  const onGroupnameInputChange = (e) => {
    setGroupNameInput(e.target.value)
  }

  const onSave = (e) => {
    e.preventDefault();
    console.log('Group create form submited :', groupName);
    setIsSubmitDisabled(true);
    createGroup({ variables: { groupname: groupName } });
  }

  return (
    <Paper className={classes.container}>
      <form className={classes.form} onSubmit={onSave} noValidate autoComplete="off">
        <div>
          <TextField
            className={classes.spacing}
            id="filled-required"
            label="Créer un groupe"
            value={groupName}
            onChange={onGroupnameInputChange}
          />
          <Button
            className={classes.submit}
            type="submit"
            variant="contained"
            disabled={isSubmitDisabled}
            color="primary"
          >
            Créer
          </Button>
        </div>
        <div>
        </div>
      </form>
    </Paper>
  )
}
