import { gql } from '@apollo/client';

export const GET_GROUP = gql`
query getGroup($groupname: String!) {
  getGroup(groupname: $groupname) {
    GroupName
    Description
    CreationDate
    LastModifiedDate
    Users {
      users {
        UserAttributes {
          Name
          Value
        }
        Enabled
        Username
        UserStatus
        UserCreateDate
      }
    }
  }
}
`;
