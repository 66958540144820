import React from 'react';
import { Auth } from 'aws-amplify';
import { AUTH_TYPE } from 'aws-appsync';
import { AuthLink } from 'aws-appsync-auth-link';
import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { persistCache } from 'apollo-cache-persist';
import config from '../../aws-exports';

export default function ApolloContainer({ children }) {
  const auth = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
  };

  const link = ApolloLink.from([
    new AuthLink({ url: config.aws_appsync_graphqlEndpoint, region: config.aws_project_region, auth }),
    createSubscriptionHandshakeLink({ url: config.aws_appsync_graphqlEndpoint, region: config.aws_project_region, auth }),
    new HttpLink({ uri: config.aws_appsync_graphqlEndpoint }),
  ]);

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  };

  const cache = new InMemoryCache();

  const initPersistCache = async () => {
    await persistCache({
      cache,
      storage: window.localStorage,
    });
  }
  initPersistCache();

  const client = new ApolloClient({
    link,
    cache,
    name: 'btp-poc',
    defaultOptions,
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}
