import { gql } from '@apollo/client';

export const GROUP_LIST = gql`
query listGroups {
  listGroups {
    groups {
      GroupName
      Description
      CreationDate
      LastModifiedDate
    }
  }
}
`;
