import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Query } from '@apollo/react-components';

import UserForm from './UserDetailForm';
import { GET_USER } from '../query/userDetailsQuery';
import { GROUP_LIST } from '../../Groups/query/groupListQuery';

import Loading from '../../../components/Loading';


export default function UserDetails() {
  const location = useLocation();
  const username = location?.state?.username;

  if (!username) {
    console.log('No email for UserDetails')
    return <Redirect to={'/'} />
  }

  return (
    <Query query={GET_USER} variables={{ username }}>
      {({ loading, data, error }) => {

        if (loading) return <Loading />

        if (error) return <p>{error.message}</p>

        if (data.getUser) {
          const userData = data.getUser;

          return (
            <Query query={GROUP_LIST} variables={{ username }}>
              {({ loading, data, error }) => {

                if (loading) return <Loading />

                if (error) return <p>{error.message}</p>

                if (data.listGroups) return <UserForm userData={userData} groupData={data.listGroups} />

              }}
            </Query>
          );
        }

      }}
    </Query>
  );
}
