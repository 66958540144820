import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';

import ConnectionHistoryRow from './ConnectionHistoryRow';

import { useEnableUserMutation, useDisableUserMutation } from '../hooks/useEnableDisableUser';


import { formatDateString } from '../../../tools/format';

export default function UserRow({ userData }) {
  const [open, setOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(userData.Enabled);

  const email = userData.UserAttributes?.find((attr) => attr.Name === 'email')?.Value;

  const [enableUser] = useEnableUserMutation({
    email,
    onCompleted: () => setIsEnabled(true)
  });

  const [disableUser] = useDisableUserMutation({
    email,
    onCompleted: () => setIsEnabled(false)
  });


  const onEnabledChange = (e) => {
    if (!isEnabled) {
      // want to enable
      enableUser({ variables: { username: email } });
    } else {
      // want to disable
      disableUser({ variables: { username: email } });
    }
  }

  let id = userData.UserAttributes?.find((attr) => attr.Name === 'sub')?.Value;
  id = id.substr(id.lastIndexOf('-') + 1);

  const lastConnexionDateString = userData.AuthEvents?.authEvents[0] && userData.AuthEvents.authEvents[0].CreationDate;
  const lastConnexionDateFormated = lastConnexionDateString ? formatDateString(new Date(lastConnexionDateString)) : "Jamais";

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {id}
        </TableCell>
        <TableCell align="center">{email}</TableCell>
        <TableCell align="center">{isEnabled ? 'Activé': 'Désactivé'}</TableCell>
        <TableCell align="center">{lastConnexionDateFormated}</TableCell>
        <TableCell align="center">
          <IconButton aria-label="access detail" size="small" component={Link} to={{
            pathname: '/user/details',
            state: {
              username: email
            }
          }}>
            {<EditIcon />}
          </IconButton>
          <Switch color="primary"
            checked={isEnabled}
            onChange={onEnabledChange}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Dernières connexions
              </Typography>
              <Table size="small" aria-label="history">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Device</TableCell>
                    <TableCell align="center">Ville / Pays</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    userData.AuthEvents?.authEvents?.map((historyData, index) => (
                      <ConnectionHistoryRow historyData={historyData} key={index} />
                    ))
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
