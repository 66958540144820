import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  FormControlLabel,
  TextField,
  Switch,
} from '@material-ui/core';
import useCustomAlert from '../../components/Alert/useCustomAlert';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  spacingLittle: {
    margin: theme.spacing(1, 0, 2),
  },
  spacing: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const updateAffairMutation = gql`
  mutation updateAffair($input: UpdateAffairInput!) {
    updateAffair(input: $input) {
      id
      title
      closed
    }
  }
`;

export default ({ data }) => {
  const classes = useStyles();
  const alert = useCustomAlert();

  const { id, updatedAt, _version } = data;

  const [title, setTitle] = useState(data.title);
  const [closed, setClosed] = useState(data.closed);

  const [updateAffair] = useMutation(updateAffairMutation, {
    onCompleted: (data) => {
      console.log('Affair update mutation completed:', data);

      alert.showSuccess(`Affair ${title} successfully updated !`);
    },
    onError: (error) => {
      console.log('Affair update mutation error:', error);

      alert.showError(error.toString());
    }
  });

  const handleClosedChange = (e) => {
    setClosed(e.target.checked);
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleSubmit = (e) => {
    console.log('[EditForm.js]:35 e: ', e)

    updateAffair({ variables: {
      input: {
        id,
        title,
        closed,
        _version,
      },
    }});
  }

  return (
    <Paper>
      <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
        <div className={classes.formControl}>
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="input-id"
            label="ID"
            value={id}
            variant="filled"
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            className={classes.spacingLittle}
            fullWidth
            id="input-title"
            label="Titre"
            value={title}
            onChange={handleTitleChange}
          />
        </div>
        <div className={classes.formControl}>
          <FormControlLabel
            className={classes.spacingLittle}
            value="top"
            control={<Switch color="primary" />}
            label="Fermé ?"
            labelPlacement="top"
            checked={closed}
            onChange={handleClosedChange}
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="input-updatedAt"
            label="Date de modification"
            value={updatedAt}
            variant="filled"
          />
        </div>
        <div className={classes.formControl}>
          <Button
            className={classes.spacingLittle}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Enregistrer
          </Button>
        </div>
      </form>
    </Paper>
  )
}
