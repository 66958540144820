import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import useCustomAlert from '../../../components/Alert/useCustomAlert'

import { ADD_USER_TO_GROUP, REMOVE_USER_TO_GROUP } from '../query/userMutation';

export default function UserGroupSelect({ userData, groupData }) {
  const [userGroupList, setUserGroupList] = useState(userData.Groups.groups);

  const alert = useCustomAlert();

  const email = userData.UserAttributes?.find((attr) => attr.Name === 'email')?.Value;

  const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
    onCompleted: (data) => {
      console.log('Enable user mutation completed:', data);
      alert.showSuccess(`Groups of user ${email} successfully updated !`);
    },
    onError: (error) => {
      console.log('Groups update mutation error:', error);
      alert.showError(error.toString());
    }
  });
  const [removeUserFromGroup] = useMutation(REMOVE_USER_TO_GROUP, {
    onCompleted: (data) => {
      console.log('Disable user mutation completed:', data);
      alert.showSuccess(`Groups of user ${email} successfully updated !`);
    },
    onError: (error) => {
      console.log('Groups update mutation error:', error);
      alert.showError(error.toString());
    }
  });

  const onUserGroupChange = (selectedGroup) => (e) => {
    if (e.target.checked) {
      addUserToGroup({ variables: { username: email, groupname: selectedGroup.GroupName } });
      setUserGroupList([...userGroupList, selectedGroup]);
    } else {
      removeUserFromGroup({ variables: { username: email, groupname: selectedGroup.GroupName } });
      setUserGroupList(userGroupList.filter((userGroup) => userGroup.GroupName !== selectedGroup.GroupName))
    }
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Groupe de l'utilisateur</FormLabel>
      <FormGroup>
        {
          groupData.groups.map((group, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!userGroupList.find((userGroup) => userGroup.GroupName === group.GroupName)}
                    onChange={onUserGroupChange(group)}
                    name={group.GroupName} />
                }
                label={group.GroupName}
                key={index}
              />
            );
          })
        }
      </FormGroup>
    </FormControl>
  )
}
