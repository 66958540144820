import React from 'react';
import { Query } from '@apollo/react-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import GroupRow from './GroupRow';
import GroupCreateForm from './GroupCreateForm';
import { GROUP_LIST } from '../query/groupListQuery';

import Loading from '../../../components/Loading';

export default function GroupList() {
  return (
    <div>
      <GroupCreateForm />
      <Query query={GROUP_LIST}>
        {({ loading, data, error }) => {
          if (loading) return <Loading />

          if (error) return <p>{error.message}</p>

          if (data.listGroups) {
            return (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow key={0}>
                      <TableCell align="center">Nom</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell align="center">Date de création</TableCell>
                      <TableCell align="center">Date de modification</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data?.listGroups?.groups?.map((group, index) => (
                        <GroupRow groupData={group} key={index}/>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        }}
      </Query>
    </div>
  );
}
