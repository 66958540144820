import { gql } from '@apollo/client';

export const GET_USER = gql`
query getUser($username: String!) {
  getUser(username: $username) {
    Username
    UserAttributes {
      Name
      Value
    }
    UserStatus
    UserCreateDate
    UserLastModifiedDate
    Enabled
    PreferredMfaSetting
    UserMFASettingList
    Groups {
      groups {
        GroupName
        Description
        CreationDate
        LastModifiedDate
      }
    }
  }
}
`;
