import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import UserGroupSelect from './UserGroupSelect';

import { useEnableUserMutation, useDisableUserMutation } from '../hooks/useEnableDisableUser';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
  },
  spacingLittle: {
    margin: theme.spacing(1, 0, 2),
  },
  spacing: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function UserForm({ userData, groupData }) {
  const classes = useStyles();

  const id = userData.Username;
  const email = userData.UserAttributes?.find((attr) => attr.Name === 'email')?.Value;
  const lastUpdateDate = userData.UserLastModifiedDate;
  const createDate = userData.UserCreateDate;

  const [isEnabled, setIsEnabled] = useState(userData.Enabled);

  const [enableUser] = useEnableUserMutation({
    email,
    onCompleted: () => setIsEnabled(true)
  });

  const [disableUser] = useDisableUserMutation({
    email,
    onCompleted: () => setIsEnabled(false)
  });

  const onEnabledChange = (e) => {
    if (!isEnabled) {
      // want to enable
      enableUser({ variables: { username: email } });
    } else {
      // want to disable
      disableUser({ variables: { username: email } });
    }
  }

  const onSave = () => {
    console.log('User form submited, not implemented yet');
  }

  return (
    <Paper>
      <form className={classes.form} onSubmit={onSave} noValidate autoComplete="off">
        <div className={classes.formControl}>
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="ID"
            value={id}
            variant="filled"
          />
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="Email"
            value={email}
            variant="filled"
          />
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="Date de création"
            value={createDate}
            variant="filled"
          />
        </div>
        <div className={classes.formControl}>
          <FormControlLabel
            className={classes.spacingLittle}
            value="top"
            control={<Switch color="primary" />}
            label="Activé ?"
            labelPlacement="top"
            checked={isEnabled}
            onChange={onEnabledChange}
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="Last update"
            value={lastUpdateDate}
            variant="filled"
          />
        </div>
        <div className={classes.formControl}>
          <UserGroupSelect userData={userData} groupData={groupData} />
        </div>
      </form>
    </Paper>
  )
}
