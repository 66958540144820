import { useMutation } from '@apollo/client';

import useCustomAlert from '../../../components/Alert/useCustomAlert'

import { ENABLE_USER, DISABLE_USER } from '../query/userMutation';

export const useEnableUserMutation = ({ email, onCompleted }) => {
  const alert = useCustomAlert();

  return useMutation(ENABLE_USER, {
    onCompleted: (data) => {
      console.log('Enable user mutation completed:', data);
      alert.showSuccess(`User ${email} successfully enabled !`)
      onCompleted && onCompleted();
    },
    onError: (error) => {
      console.log('Enable user mutation error:', error);
      alert.showError(error.toString());
    }
  });
}

export const useDisableUserMutation = ({ email, onCompleted }) => {
  const alert = useCustomAlert();

  return useMutation(DISABLE_USER, {
    onCompleted: (data) => {
      console.log('Enable user mutation completed:', data);
      alert.showSuccess(`User ${email} successfully enabled !`)
      onCompleted && onCompleted();
    },
    onError: (error) => {
      console.log('Enable user mutation error:', error);
      alert.showError(error.toString());
    }
  });
}

