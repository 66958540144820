import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Query } from '@apollo/react-components';

import GroupDetailForm from './GroupDetailForm';
import { GET_GROUP } from '../query/groupDetailsQuery';

import Loading from '../../../components/Loading';

export default function GroupDetails() {
  const location = useLocation();
  const groupname = location?.state?.groupname;

  if (!groupname) {
    console.log('No groupname for GroupDetails')
    return <Redirect to={'/group/list'} />
  }

  return (
    <Query query={GET_GROUP} variables={{ groupname }}>
      {({ loading, data, error }) => {

        if (loading) return <Loading />

        if (error) return <p>{error.message}</p>

        if (data.getGroup) return <GroupDetailForm groupData={data.getGroup}/>

      }}
    </Query>
  );
}
