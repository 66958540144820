import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import useCustomAlert from '../../../components/Alert/useCustomAlert'

import { UPDATE_GROUPE } from '../query/groupMutation';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  spacingLittle: {
    margin: theme.spacing(1, 0, 2),
  },
  spacing: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function GroupForm({ groupData }) {
  const classes = useStyles();

  const groupname = groupData.GroupName;
  const description = groupData.Description || "";
  const creationDate =  groupData.CreationDate;
  const modificationDate =  groupData.LastModifiedDate;

  const [groupDescInput, setGroupDescInput] = useState(description);

  const alert = useCustomAlert();

  const [updateGroup] = useMutation(UPDATE_GROUPE, {
    onCompleted: (data) => {
      console.log('Group update completed:', data);
      alert.showSuccess(`Group ${groupname} successfully updated !`);
    },
    onError: (error) => {
      console.log('Group update error:', error);
      alert.showError(error.toString());
    }
  });

  const onSave = (e) => {
    e.preventDefault()
    updateGroup({ variables: { groupname, description: groupDescInput } });
  }

  const onGroupDescInputChange = (e) => {
    setGroupDescInput(e.target.value)
  }

  return (
    <Paper>
      <form className={classes.form} onSubmit={onSave} noValidate autoComplete="off">
        <div>
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="Nom"
            value={groupname}
            variant="filled"
          />
          <TextField
            className={classes.spacingLittle}
            fullWidth
            id="filled-required"
            label="Description"
            value={groupDescInput}
            onChange={onGroupDescInputChange}
          />
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="Date de création"
            value={creationDate}
            variant="filled"
          />
          <TextField
            className={classes.spacingLittle}
            disabled
            fullWidth
            id="filled-required"
            label="Last update"
            value={modificationDate}
            variant="filled"
          />
        </div>
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.spacing}
          >
            Sauvegarder
          </Button>
        </div>
      </form>
    </Paper>
  )
}
