import { gql } from '@apollo/client';

export const CREATE_USER = gql`
mutation createUser($username: AWSEmail!) {
  createUser(username: $username){
    Username
    UserAttributes {
      Name
      Value
    }
    UserStatus
    UserCreateDate
    Enabled
  }
}
`;

export const ENABLE_USER = gql`
mutation enableUser($username: AWSEmail!) {
  enableUser(username: $username)
}
`;

export const DISABLE_USER = gql`
mutation disableUser($username: AWSEmail!) {
  disableUser(username: $username)
}
`;


export const ADD_USER_TO_GROUP = gql`
mutation addUserToGroup($username: AWSEmail!, $groupname: String!) {
  addUserToGroup(username: $username, groupname: $groupname)
}
`;

export const REMOVE_USER_TO_GROUP = gql`
mutation removeUserFromGroup($username: AWSEmail!, $groupname: String!) {
  removeUserFromGroup(username: $username, groupname: $groupname)
}
`;
