import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import EditForm from './EditForm';
import Loading from '../../components/Loading';

const getAffair = gql`
  query getAffair($id: ID!) {
    getAffair(id: $id) {
      id
      title
      closed
      createdAt
      updatedAt,
      _version
    }
  }
`;

export default () => {
  const params = useParams();

  const id = params.id;

  if (!id) {
    return <Redirect to={'/'} />
  }

  const {data, loading, error} = useQuery(getAffair, { variables: { id } });

  if (loading) {
    return (
      <Loading />
    );
  }

  if (error) {
    return (
      <p>{error.message}</p>
    );
  }

  if (data?.getAffair) {
    return (
      <EditForm data={data.getAffair} />
    );
  }

  return '';
}
