import React from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import ConfirmDialog from '../../../components/ConfirmDialog';
import useCustomAlert from '../../../components/Alert/useCustomAlert'

import { DELETE_GROUP } from '../query/groupMutation';

import { formatDateString } from '../../../tools/format';

export default function GroupRow({ groupData }) {
  const alert = useCustomAlert();

  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted: (data) => {
      console.log('Group deletion completed:', data);
      alert.showSuccess(`Group ${groupname} successfully deleted !`);
    },
    onError: (error) => {
      console.log('Group deletion error:', error);
      alert.showError(error.toString());
    }
  });

  const groupname = groupData.GroupName;
  const description = groupData.Description;
  const creationDate =  formatDateString(new Date(groupData.CreationDate));
  const modificationDate =  formatDateString(new Date(groupData.LastModifiedDate));

  const handleDeleteGroup = () => {
    deleteGroup({ variables: { groupname } });
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="center">{groupname}</TableCell>
        <TableCell align="center">{description}</TableCell>
        <TableCell align="center">{creationDate}</TableCell>
        <TableCell align="center">{modificationDate}</TableCell>
        <TableCell align="center">
          <IconButton aria-label="access detail" size="small" component={Link} to={{
            pathname: '/group/details',
            state: {
              groupname
            }
          }}>
            {<EditIcon />}
          </IconButton>
          <ConfirmDialog
            dialogTitle="Confirmer la suppression"
            dialogText={`Merci de confirmer la suppression du groupe [${groupname}]`}
            onConfirm={handleDeleteGroup}
          >
            <IconButton aria-label="access detail" size="small">
              {<DeleteForeverIcon style={{fill: "red"}}/>}
            </IconButton>
          </ConfirmDialog>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
