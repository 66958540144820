import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import useCustomAlert from '../../../components/Alert/useCustomAlert'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function UpdateFirstPassword() {
  const classes = useStyles();

  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const location = useLocation();
  const alert = useCustomAlert();

  const userData = location.state.userData;

  const updatePassword = async (newPassword) => {
    try {
      // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#complete-new-password
      const { requiredAttributes } = userData.challengeParam;
      const userObj = await Auth.completeNewPassword(
          userData,
          newPassword,
          ...requiredAttributes
      );
      console.log(userObj)
    } catch (error) {
      console.log('error signing in', error);
      alert.showError(`Password error : ${error.message}`);
    }
  }

  const onPasswordChange1 = (event) => {
    setNewPassword1(event.target.value);
  }

  const onPasswordChange2 = (event) => {
    setNewPassword2(event.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (newPassword1 !== newPassword2) {
      setNewPassword1('');
      setNewPassword2('');
      alert.showError('Passwords does not match !')
      return;
    }

    updatePassword(newPassword1);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Update your temporary password
        </Typography>
        <form className={classes.form} onSubmit={onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password1"
            autoComplete="current-password"
            value={newPassword1}
            onChange={onPasswordChange1}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Repeat password"
            type="password"
            id="password2"
            autoComplete="current-password"
            value={newPassword2}
            onChange={onPasswordChange2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
