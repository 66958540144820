import React from 'react';
import { Query } from '@apollo/react-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import UserRow from './UserRow';
import UserCreateForm from './UserCreateForm';
import { USER_LIST } from '../query/userListQuery';

import Loading from '../../../components/Loading';

export default function UserList() {
  return (
    <div>
      <UserCreateForm />
      <Query query={USER_LIST}>
        {({ loading, data, error }) => {
          if (loading) return <Loading />

          if (error) return <p>{error.message}</p>

          if (data.listUsers) {
            return (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow key={0}>
                      <TableCell />
                      <TableCell align="center">ID</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Dernière connexion</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data?.listUsers?.users?.map((user, index) => (
                        <UserRow userData={user} key={index}/>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }
        }}
      </Query>
    </div>
  );
}
