import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import useCustomAlert from '../../../components/Alert/useCustomAlert'

import { CREATE_USER } from '../query/userMutation';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  spacing: {
    margin: theme.spacing(3, 2, 2),
  },
}));

export default function UserCreateForm() {
  const classes = useStyles();
  const alert = useCustomAlert();

  const [emailInput, setEmailInput] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      console.log('User create mutation completed:', data);
      alert.showSuccess(`User ${emailInput} successfully created !`);
      setIsSubmitDisabled(false);
      setEmailInput("");
    },
    onError: (error) => {
      console.log('User create mutation error:', error);
      alert.showError(error.toString());
      setIsSubmitDisabled(false);
    }
  });

  const onEmailInputChange = (e) => {
    setEmailInput(e.target.value)
  }

  const onSave = (e) => {
    e.preventDefault();
    console.log('User create form submited :', emailInput);
    setIsSubmitDisabled(true);
    createUser({ variables: { username: emailInput } });
  }

  return (
    <Paper className={classes.container}>
      <form className={classes.form} onSubmit={onSave} noValidate autoComplete="off">
        <div>
          <TextField
            className={classes.spacing}
            id="filled-required"
            label="Créer un utilisateur"
            value={emailInput}
            onChange={onEmailInputChange}
          />
          <Button
            className={classes.submit}
            type="submit"
            variant="contained"
            disabled={isSubmitDisabled}
            color="primary"
          >
            Créer
          </Button>
        </div>
        <div>
        </div>
      </form>
    </Paper>
  )
}
