import { gql } from '@apollo/client';

export const CREATE_GROUPE = gql`
mutation createGroup($groupname: String!) {
  createGroup(groupname: $groupname) {
    GroupName
    Description
    CreationDate
    LastModifiedDate
  }
}
`;

export const UPDATE_GROUPE = gql`
mutation updateGroup($groupname: String!, $description: String) {
  updateGroup(groupname: $groupname, description: $description) {
    GroupName
    Description
    CreationDate
  }
}
`;

export const DELETE_GROUP = gql`
mutation deleteGroup($groupname: String!) {
  deleteGroup(groupname: $groupname)
}
`;
