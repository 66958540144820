import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import useCustomAlert from '../../components/Alert/useCustomAlert';

const createAffairMutation = gql`
  mutation createAffair($input: CreateAffairInput!) {
    createAffair(input: $input) {
      id
      title
      closed
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  spacing: {
    margin: theme.spacing(3, 2, 2),
  },
}));

export default () => {
  const classes = useStyles();
  const alert = useCustomAlert();

  const [titleInput, setTitleInput] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [createAffair] = useMutation(createAffairMutation, {
    onCompleted: (data) => {
      console.log('Affair create mutation completed:', data);

      alert.showSuccess(`Affair ${titleInput} successfully created !`);

      setIsSubmitDisabled(false);
      setTitleInput("");
    },
    onError: (error) => {
      console.log('Affair create mutation error:', error);

      alert.showError(error.toString());

      setIsSubmitDisabled(false);
    }
  });

  const onSave = (e) => {
    e.preventDefault();

    console.log('Affair create form submited :', titleInput);
    setIsSubmitDisabled(true);

    createAffair({ variables: { input: { title: titleInput, closed: false } } });
  }

  return (
    <Paper className={classes.container}>
      <form className={classes.form} onSubmit={onSave} noValidate autoComplete="off">
        <div>
          <TextField
            className={classes.spacing}
            id="filled-required"
            label="Créer une affair"
            value={titleInput}
            onChange={e => setTitleInput(e.target.value)}
          />
          <Button
            className={classes.submit}
            type="submit"
            variant="contained"
            disabled={isSubmitDisabled}
            color="primary"
          >
            Créer
          </Button>
        </div>
      </form>
    </Paper>
  )
}
