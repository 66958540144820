import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { formatDateString } from '../../../tools/format';

export default function ConnectionHistoryRow({ historyData }) {
  const date = formatDateString(new Date(historyData.CreationDate));
  const type = historyData.EventType;
  const device = historyData.EventContextData.DeviceName;
  const location = `${historyData.EventContextData.City} / ${historyData.EventContextData.Country}`

  return (
    <TableRow>
      <TableCell align="center">{date}</TableCell>
      <TableCell align="center">{type}</TableCell>
      <TableCell align="center">{device}</TableCell>
      <TableCell align="center">{location}</TableCell>
    </TableRow>
  );
}
