import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import Row from './Row';
import CreateForm from './CreateForm';
import Loading from '../../components/Loading';

const ListAffairs = gql`
  query listAffairs {
    listAffairs {
      items {
        id
        title
        closed
        createdAt
        updatedAt
      }
    }
  }
`;

const SubscriptionAffair = gql`
  subscription subAffair {
    onCreateAffair {
      id
      title
      closed
    }
  }
`;

export default () => {
  const {loading, data, error, subscribeToMore} = useQuery(ListAffairs);

  if (loading) {
    return (
      <Loading />
    );
  }

  if (error) {
    return (
      <p>{error.message}</p>
    );
  }

  if (subscribeToMore) {
    subscribeToMore({
      document: SubscriptionAffair,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newFeedItem = subscriptionData.data.onCreateAffair;

        return {
          listAffairs: {
            items: [
              ...prev.listAffairs.items,
              newFeedItem
            ],
          },
        };
      }
    });
  }

  return (
    <div>
      <CreateForm />
      {
        data?.listAffairs && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow key={0}>
                  <TableCell />
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Date création</TableCell>
                  <TableCell align="center">Date modification</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data?.listAffairs?.items?.map((item) => (
                    <Row data={item} key={item.id} />
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
    </div>
  );
}
