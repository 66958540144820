import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
  TableCell,
  TableRow,
  Switch,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const closeAffairMutation = gql`
  mutation closeAffair($id: ID!) {
    closeAffair(id: $id)
  }
`;

const openAffairMutation = gql`
  mutation openAffair($id: ID!) {
    openAffair(id: $id)
  }
`;

export default ({ data }) => {
  const {
    id,
    title,
    // closed,
    createdAt,
    updatedAt,
  } = data;

  const [closed, setClosed] = useState(data.closed);

  const [closeAffair] = useMutation(closeAffairMutation, {
    onCompleted: () => setClosed(true),
  });

  const [openAffair] = useMutation(openAffairMutation, {
    onCompleted: () => setClosed(false)
  });

  const onEnabledChange = () => {
    if (!closed) {
      closeAffair({ variables: { id }});
    } else {
      openAffair({ variables: { id }});
    }
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell align="center">{title}</TableCell>
      <TableCell align="center">{!closed ? 'Activé': 'Désactivé'}</TableCell>
      <TableCell align="center">{createdAt}</TableCell>
      <TableCell align="center">{updatedAt}</TableCell>
      <TableCell align="center">
        <Link to={`/affair/${id}/details`}>Voir</Link>
        <Switch color="primary"
          checked={closed}
          onChange={onEnabledChange}
        />
      </TableCell>
    </TableRow>
  );
}
