import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import Amplify, { Auth, Hub } from 'aws-amplify';

import config from '../../aws-exports'

import ApolloContainer from './ApolloContainer';

import Login from '../Auth/Login';
import UpdateFirstPassword from '../Auth/UpdateFirstPassword';

import UserList from '../Users/List';
import UserDetails from '../Users/Details';

import GroupList from '../Groups/List';
import GroupDetails from '../Groups/Details';

import AffairList from '../Affair/List';
import AffairDetails from '../Affair/Details';

import CustomAlert from '../../components/Alert';
import PageStructure from '../../components/PageStructure';

import menuData from './menu.json';

Amplify.configure(config);

function App() {
  const [userObj, setUserObj] = useState(null);

  const getCurrentAuthenticatedUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch (err) {
      console.log('Not signed in');
    }
  }

  // https://docs.amplify.aws/lib/utilities/hub/q/platform/js#authentication-events
  Hub.listen('auth', async ({ payload: { event, data } }) => {
    console.log(event);
    switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
        const userData = await getCurrentAuthenticatedUser();
        setUserObj(userData);
        break;
      case 'signOut':
        setUserObj(null)
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
        console.log('Sign in failure', data);
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    const tryAuthentificationFromCache = async () => {
      const userData = await getCurrentAuthenticatedUser();
      setUserObj(userData);
    }

    tryAuthentificationFromCache();
  }, []);

  return (
    <ApolloContainer>
      <SnackbarProvider SnackbarComponent={CustomAlert}>
        <BrowserRouter>
          <div className="App">
            {
              userObj ? (
                <div>
                  <PageStructure menuData={menuData}>
                    <Switch>
                      <Route path="/user/list" component={UserList} />
                      <Route path="/user/details" component={UserDetails} />
                      <Route path="/group/list" component={GroupList} />
                      <Route path="/group/details" component={GroupDetails} />
                      <Route path="/affair/list" component={AffairList} />
                      <Route path="/affair/:id/details" component={AffairDetails} />
                      <Route component={UserList} />
                    </Switch>
                  </PageStructure>
                </div>
              )
                : (
                  <div>
                    <Switch>
                      <Route exact path='/updatefirstpassword' component={UpdateFirstPassword} />
                      <Route component={Login} />
                    </Switch>
                  </div>
                )
            }
          </div>
        </BrowserRouter>
      </SnackbarProvider>
    </ApolloContainer>
  );
}

export default App;
